import request from '@/router/axios';
import { pvhApiBase } from '@/config/env'

//�б�
export const purOrderList = (page, pageSize, vendor, businessnoin, businessnoout, code, batch, color, po, beginTime, endTime, id, status, otherStatus, traingStatus, UTbegin, UTend) => {
    return request({
        url: pvhApiBase + "Tracing/GetPurchaseOrders",
        method: "get",
        params: {
            "businessnoin": businessnoin,
            "businessnoout": businessnoout,
            "code": code,
            "batch": batch,
            "color": color,
            "vendor": vendor,
            "po": po,
            "beginOrderDate": beginTime,
            "endOrderDate": endTime,
            "id": id,
            "status": status,
            "otherStatus": otherStatus,
            "traingStatus": traingStatus,
            "UTbegin": UTbegin,
            "UTend": UTend,
            "page": page,
            "pageSize": pageSize
        }
    })
}


//���ݲɹ�id��ȡ����������Ϣ
export const SoListByPoId = (id) => {
    return request({
        url: pvhApiBase + "Tracing/GetSaleOrderListByPOId",
        method: "get",
        params: {
            "id": id
        }
    })
}



//���ݲɹ���ϸid��ȡ����������Ϣ
export const SoListByPoDetailId = (id) => {
    return request({
        url: pvhApiBase + "Tracing/GetSaleOrderListByPoDetailId",
        method: "get",
        params: {
            "id": id
        }
    })
}

//���ݺ�ͬid��ȡ�ɹ���Ϣ
export const POListById = (id) => {
    return request({
        url: pvhApiBase + "Tracing/GetPurchaseOrderListByContractId",
        method: "get",
        params: {
            "id": id
        }
    })
}

//��ȡ�¼������б�
export const AllPOList = (PurchaseId) => {
    return request({
        url: pvhApiBase + "Tracing/GetTraingAllPurchaseOrders",
        method: "get",
        params: {
            "PurchaseId": PurchaseId
        }
    })
}

//��ȡpo��������Ϣ
export const PoAndDetailList = (page, pageSize, vendor, code, batch, color, no_SeaCodeVal,seaCode, beginTime, endTime) => {
    return request({
        url: pvhApiBase + "Tracing/GetPoAndDetailList",
        method: "get",
        params: {
            "code": code,
            "batch": batch,
            "color": color,
            "vendor": vendor,
            "no_SeaCodeVal": no_SeaCodeVal,
            "seaCode": seaCode,
            "beginOrderDate": beginTime,
            "endOrderDate": endTime,
            "page": page,
            "pageSize": pageSize
        }
    })
}

//����
export const addPOHead = (params) => {
    return request({
        url: pvhApiBase + "Tracing/PostPurchaseOrder",
        method: "post",
        data: {
            ...params
        }
    })
}

//�༭
export const editHead = (params) => {
    return request({
        url: pvhApiBase + "Tracing/PutPurchaseOrder",
        method: "put",
        data: {
            ...params
        }
    })
}

//�ָ�
export const recoverHead = (id) => {
    return request({
        url: pvhApiBase + "Tracing/RecoverPurchaseOrder",
        method: "delete",
        params: {
            id
        }
    })
}

//ɾ��
export const delHead = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeletePurchaseOrder",
        method: "delete",
        params: {
            id
        }
    })
}

//����
export const addPOBody = (params) => {
    return request({
        url: pvhApiBase + "Tracing/PostPurchaseOrderDetail",
        method: "post",
        data: {
            ...params
        }
    })
}

//�༭
export const editBody = (params) => {
    return request({
        url: pvhApiBase + "Tracing/PutPurchaseOrderDetail",
        method: "put",
        data: {
            ...params
        }
    })
}

//ɾ��
export const delBody = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeletePurchaseOrderDetail",
        method: "delete",
        params: {
            id
        }
    })
}





//vendorvection ��Ӧ�����
export const vendorvectionlist = () => {
    return request({
        url: pvhApiBase + "ProcessManagement/GetCorporations",
        method: "get",
    })

}



//�걨���
export const DecCompleted = (id, isBodyType) => {
    return request({
        url: pvhApiBase + "Tracing/PutDecCompletedPO",
        method: "put",
        params: {
            "id": id,
            "isBodyType": isBodyType
        }
    })
}

//ȡ���걨���
export const UnDecCompleted = (id, isBodyType) => {
    return request({
        url: pvhApiBase + "Tracing/PutUnDecCompletedPO",
        method: "put",
        params: {
            "id": id,
            "isBodyType": isBodyType
        }
    })
}

//��ȡ���۵�����
export const POInfo = (id, orderId, brandBusinessId, vendorSectionId) => {
    return request({
        url: pvhApiBase + "Tracing/GetPurchaseOrderCanRead",
        method: "get",
        params: {
            "id": id,
            "orderId": orderId,
            "brandBusinessId": brandBusinessId,
            "vendorSectionId": vendorSectionId
        }
    })
}


export function getDate(d) {
    var date = new Date(d); //���ڶ���
    var yTime = date.getFullYear();
    var MTime = date.getMonth() + 1;
    var dTime = date.getDate();
    var hTime = date.getHours();
    var Ftime = date.getMinutes();
    var sTime = date.getSeconds();
    if (MTime < 10) {
        MTime = "0" + MTime;
    }
    if (dTime < 10) {
        dTime = "0" + dTime;
    }
    if (hTime < 10) {
        hTime = "0" + hTime;
    }
    if (Ftime < 10) {
        Ftime = "0" + Ftime;
    }
    if (sTime < 10) {
        sTime = "0" + sTime;
    }
    return yTime + "-" + MTime + "-" + dTime + " " + hTime + ":" + Ftime ;
}

export function getDateYYYMMDD(d) {
    var date = new Date(d); //���ڶ���
    var yTime = date.getFullYear();
    var MTime = date.getMonth() + 1;
    var dTime = date.getDate();
    if (MTime < 10) {
        MTime = "0" + MTime;
    }
    if (dTime < 10) {
        dTime = "0" + dTime;
    }
    return yTime + "-" + MTime + "-" + dTime ;
}